import request from '@/service/request'

export default {
  ...request,
  base_url: 'https://staging.api-huaydragon.com/agent/',
  // base_url: 'http://localhost:5000/',
  getURL() {
    return this.base_url
  },
}
