<template>
  <validation-observer ref="simpleRules">
    <b-card
      style="max-width: 1200px;"
    >
      <b-form @submit.prevent>
        <b-card-text>
          <strong>{{ $t('General Information') }}</strong>
        </b-card-text>
        <b-row class="mt-2 mb-2 align-items-center  pl-lg-5 pr-lg-5 pl-md-2 pr-md-2">
          <b-col md="6">
            <b-form-group
              label="Username"
              label-for="mc-username"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="mc-username"
                  v-model="form.username"
                  placeholder="Username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Phone"
              label-for="mc-phone"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required|digits:10"
              >
                <b-form-input
                  id="mc-phone"
                  v-model="form.phone"
                  placeholder="Phone"
                  minlength="10"
                  maxlength="10"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Password"
              label-for="mc-password"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|password"
              >
                <b-form-input
                  id="mc-password"
                  v-model="form.password"
                  placeholder="Password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Confirm Password"
              label-for="mc-confirm-password"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="mc-confirm-password"
                  v-model="form.passwordConfirm"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Confirm Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nickname"
              label-for="mc-nickname"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Nickname"
                rules="required"
              >
                <b-form-input
                  id="mc-nickname"
                  v-model="form.nickName"
                  placeholder="Nickname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-card-text style="margin-bottom: 0px;">
          <strong>{{ $t('Permission') }}</strong>
        </b-card-text>
        <div
          style="max-width: 450px"
          class="ml-lg-3 mt-2 mb-1"
        >
          <AbilityForm
            ref="abilityForm"
            :ability="ability"
            @onSubmit="onSubmit"
          />
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click.prevent="onSubmitForm"
        >
          Submit
        </b-button>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
/* eslint-disable */
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AbilityForm from '@/views/components/AbilityForm'
import agentService from '@/service/agentService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormRadio,
    AbilityForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      abilityDisble: false,
      ability: [],
      form: {
        username: '',
        password: '',
        passwordConfirm: '',
        nickName: '',
        ability: [],
      },
      // abilityForm: [0, 0, 0, 0, 0, 0],
      actionMap: {
        1: 'read',
        2: 'update'
      }
    }
  },
  mounted() {
  },
  methods: {
    onSubmit(v) {
      // if (v.length === 1 && v[0].subject === 'all' && v[0].action === 'manage') {
      //   const rules = this.$ability.rules
      //   const isMaster = rules.length === 1 && rules[0].subject === 'all' && rules[0].action === 'manage'
      //   if (isMaster === false) {
      //     console.log(this.$refs.abilityForm.getAll())
      //   } else {
      //     this.form.ability = v
      //   }
      // } else {
      //   this.form.ability = v
      // }
      this.form.ability = this.$refs.abilityForm.buildPermission(v);
    },
    onSubmitForm() {
      this.$refs.abilityForm.onSubmit()
      this.$refs.simpleRules.validate()
          .then(async success => {
            if (success) {
              this.$swal({
                dark: true,
                title: 'Are you sure?',
                text: 'คุณเเน่ใจว่าต้องการเพิ่มข้อมูลใช่หรือไม่',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
                  .then(async result => {
                    if (result.value) {
                      const formSender = { ...this.form }
                      delete formSender.passwordConfirm

                      const res = await agentService.postAuthen('subAccount', formSender)
                      if (res.status === 200) {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'success',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: res.data,
                          },
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data,
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
