import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'
import Cookies from 'js-cookie'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  // return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return localStorage.getItem('userData') && Cookies.get(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// export const getHomeRouteForLoggedInUser = userRole => {
//   if (userRole === 'admin' || userRole === 'sub') return '/'
//   // if (userRole === 'sub') return { name: 'dashboard-ecommerce' }
//   // if (userRole === 'client') return { name: 'access-control' }
//   return { name: 'auth-login' }
// }

const firstPagesOption = {
  all: 'dashboard-statistics',
  dashboard: 'dashboard-statistics',
  summary: 'total-bet',
  member: 'add-agent',
  report: 'win-lose',
  lotto: 'lotto-list',
  api: 'api-setting',
  Auth: 'my-account',
}

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin' || userRole === 'sub') return '/'
  return { name: 'auth-login' }
}

export const getFirstPageForLoggedInUser = () => {
  if (ability.rules.length > 0) {
    return { name: firstPagesOption[ability.rules[0].subject] }
  }
  return { name: 'my-account' }
}
