<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card
        class="pt-1 pb-1"
        style="max-width: 1200px;"
      >
        <b-form @submit.prevent>
          <b-card-text>
            <h4>{{ $t('General Information') }}</h4>
          </b-card-text>
          <b-row class="mt-2 mb-2 align-items-center  pl-lg-5 pr-lg-5 pl-md-2 pr-md-2">
            <b-col md="6">
              <b-form-group
                label="Prefix"
                label-for="mc-prefix"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Prefix"
                  rules="required"
                >
                  <b-form-input
                    id="mc-prefix"
                    v-model="form.prefix"
                    placeholder="Prefix"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Username"
                label-for="mc-username"
                label-cols-md="4"
                class="ml-lg-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="mc-username"
                    v-model="form.username"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Password"
                label-for="mc-password"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8|password"
                >
                  <b-form-input
                    id="mc-password"
                    v-model="form.password"
                    placeholder="Password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Confirm Password"
                label-for="mc-confirm-password"
                label-cols-md="4"
                class="ml-lg-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    id="mc-confirm-password"
                    v-model="form.passwordConfirm"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Confirm Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nickname"
                label-for="mc-nickname"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nickname"
                  rules="required"
                >
                  <b-form-input
                    id="mc-nickname"
                    v-model="form.nickName"
                    placeholder="Nickname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Phone"
                label-for="mc-phone"
                label-cols-md="4"
                class="ml-lg-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required|digits:10"
                >
                  <b-form-input
                    id="mc-phone"
                    v-model="form.phone"
                    placeholder="Phone"
                    minlength="10"
                    maxlength="10"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="mc-email"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email"
                >
                  <b-form-input
                    id="mc-email"
                    v-model="form.email"
                    placeholder="Email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="$can('update', 'agent')"
              md="6"
            >
              <b-form-group
                label="Create Agent"
                label-for="mc-phone"
                label-cols-md="4"
                class="ml-lg-3"
              >
                <div class="inline-spacing">
                  <b-form-radio
                    v-model="form.canCreateAgent"
                    class="custom-control-primary"
                    :value="true"
                  >
                    {{ $t('yes') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.canCreateAgent"
                    class="custom-control-secondary ml-1"
                    :value="false"
                  >
                    {{ $t('no') }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="$can('update', 'poy')"
              md="6"
            >
              <b-form-group
                label="Web Api"
                label-for="mc-phone"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <div class="inline-spacing">
                  <b-form-radio
                    v-model="form.canWebApi"
                    class="custom-control-primary"
                    :value="true"
                  >
                    {{ $t('yes') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.canWebApi"
                    class="custom-control-secondary ml-1"
                    :value="false"
                  >
                    {{ $t('no') }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="$can('update', 'poy')"
              md="6"
            >
              <b-form-group
                label="Send Poy"
                label-for="mc-phone"
                label-cols-md="4"
                class="ml-lg-3"
              >
                <div class="inline-spacing">
                  <b-form-radio
                    v-model="form.canSendPoy"
                    class="custom-control-primary"
                    :value="true"
                  >
                    {{ $t('yes') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.canSendPoy"
                    class="custom-control-secondary ml-1"
                    :value="false"
                  >
                    {{ $t('no') }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="$can('update', 'payRate')"
              md="6"
            >
              <b-form-group
                label="Custom Pay Rate"
                label-for="mc-phone"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <div class="inline-spacing">
                  <b-form-radio
                    v-model="form.canCustomPayRate"
                    class="custom-control-primary"
                    :value="true"
                  >
                    {{ $t('yes') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.canCustomPayRate"
                    class="custom-control-secondary ml-1"
                    :value="false"
                  >
                    {{ $t('no') }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col
              cols="12"
              sm="6"
            >
              <b-card-text>
                <h4>{{ $t('Credit Setting') }}</h4>
              </b-card-text>
              <div v-if="creditSetting.load">
                <div
                  v-if="creditSetting.isMaster"
                  class="pl-lg-5 pl-md-2"
                >
                  <b-form-group
                    label="Wallet Type"
                    label-for="mc-phone"
                    label-cols-md="4"
                    class="mr-lg-3"
                  >
                    <div class="inline-spacing">
                      <b-form-radio
                        v-model="form.walletType"
                        class="custom-control-primary"
                        :value="0"
                      >
                        Single
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.walletType"
                        class="custom-control-info ml-1"
                        :value="1"
                      >
                        Transfer
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </div>
                <div
                  v-if="form.walletType === 0"
                  class="pl-lg-5 pl-md-2"
                >
                  <b-form-group
                    :label="$t('Our PT(%)')"
                    label-for="our-pt"
                    label-cols-md="4"
                    class="mr-lg-3"
                  >
                    <b-form-input
                      id="mc-our-pt"
                      v-model="ourPercent"
                      :placeholder="$t('Our PT(%)')"
                      disabled
                    />
                  </b-form-group>
                </div>
                <div
                  v-if="form.walletType === 0"
                  class="pl-lg-5 pl-md-2"
                >
                  <b-form-group
                    :label="$t('Given PT(%)')"
                    label-for="mc-pt"
                    label-cols-md="4"
                    class="mr-lg-3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Given PT(%)')"
                      rules="required|decimal|min_value:1|max_value:100"
                    >
                      <b-form-input
                        id="mc-pt"
                        v-model="form.percent"
                        type="number"
                        :placeholder="$t('Given PT(%)')"
                        @input="finishInputPercent"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  v-if="form.walletType === 1 && creditSetting.isMaster === false"
                  class="pl-lg-5 pl-md-2"
                >
                  <b-form-group
                    label="Your Credit"
                    label-for="mc-pt"
                    label-cols-md="4"
                    class="mr-lg-3"
                  >
                    <b-form-input
                      id="mc-pt"
                      v-model="creditSetting.credit"
                      type="number"
                      placeholder="Credit"
                      readonly
                    />
                  </b-form-group>
                </div>
                <div
                  v-if="form.walletType === 1"
                  class="pl-lg-5 pl-md-2"
                >
                  <b-form-group
                    label="Credit"
                    label-for="mc-pt"
                    label-cols-md="4"
                    class="mr-lg-3"
                  >
                    <validation-provider
                      v-if="creditSetting.isMaster"
                      #default="{ errors }"
                      name="Credit"
                      rules="decimal|min_value:0"
                    >
                      <b-form-input
                        id="mc-pt"
                        v-model="form.credit"
                        type="number"
                        placeholder="Credit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider
                      v-else
                      #default="{ errors }"
                      name="Credit"
                      :rules="`decimal|min_value:0|max_value:${creditSetting.credit}`"
                    >
                      <b-form-input
                        id="mc-pt"
                        v-model="form.credit"
                        type="number"
                        placeholder="Credit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  v-if="$can('update', 'poy') && form.canSendPoy"
                  class="pl-lg-5 pl-md-2"
                >
                  <b-form-group
                    label="Credit Poy"
                    label-for="mc-pt"
                    label-cols-md="4"
                    class="mr-lg-3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Credit"
                      rules="decimal|min_value:1"
                    >
                      <b-form-input
                        id="mc-pt"
                        v-model="form.partnerCredit"
                        type="number"
                        placeholder="Credit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="form.canWebApi && creditSetting.load"
              cols="12"
              sm="6"
            >
              <b-card-text>
                <h4>{{ $t('Etc Setting') }}</h4>
              </b-card-text>
              <div
                class="pr-lg-5 pr-md-2"
              >
                <b-form-group
                  label="Website URL"
                  label-for="mc-website-url"
                  label-cols-md="4"
                  class="ml-lg-3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Website URL"
                    rules="url"
                  >
                    <b-form-input
                      id="mc-website-url"
                      v-model="form.websiteUrl"
                      placeholder="Website URL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div
                v-if="form.walletType === 0"
                class="pr-lg-5 pr-md-2"
              >
                <b-form-group
                  label="CallBack URL"
                  label-for="mc-callback-url"
                  label-cols-md="4"
                  class="ml-lg-3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CallBack URL"
                    rules="url"
                  >
                    <b-form-input
                      id="mc-callback-url"
                      v-model="form.callbackUrl"
                      placeholder="Callback URL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div
                class="pr-lg-5 pr-md-2"
              >
                <div
                  role="group"
                  class="form-row form-group ml-lg-3"
                >
                  <div class="col-md-4 col-form-label">IP Whitelist
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="btn-icon "
                      @click="addItem()"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </div>
                  <div
                    ref="whitelistForm"
                    class="col mt-25 repeater-form"
                    :style="{height: trHeight}"
                  >
                    <div
                      v-for="(v,i) in form.ipWhiteList"
                      :key="i"
                      ref="row"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="IP Whitelist"
                        rules="regex:^\d+\.\d+\.\d+\.\d+$"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="form.ipWhiteList[i]"
                            placeholder="IP Address"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              @click="removeItem(i)"
                            >
                              <feather-icon icon="XIcon" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="onSubmitForm"
          >
            Submit
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BForm, BButton, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { validatorDecimal } from '@core/utils/validations/validators'
import { heightTransition } from '@core/mixins/ui/transition'
import agentService from '@/service/agentService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BForm,
    BButton,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      ourPercent: null,
      form: {
        prefix: '',
        username: '',
        password: '',
        passwordConfirm: '',
        nickName: '',
        phone: '',
        email: '',
        percent: '0',
        websiteUrl: '',
        callbackUrl: '',
        ipWhiteList: [''],
        canCreateAgent: true,
        canSendPoy: false,
        canWebApi: true,
        canCustomPayRate: false,
        partnerCredit: 0,
        walletType: 0,
        credit: 0,
      },
      startPercent: 0,
      creditSetting: {
        load: false,
        isMaster: false,
        credit: 0,
        walletType: 0,
      },
    }
  },
  mounted() {
    agentService.getAuthen('percent').then(res => {
      if (res.status === 200) {
        this.creditSetting.load = true
        this.creditSetting.isMaster = res.data.isMaster
        this.creditSetting.credit = res.data.credit
        this.creditSetting.walletType = res.data.walletType
        if (this.creditSetting.isMaster === false) {
          this.form.walletType = res.data.walletType
        }
        this.percent = res.data.percent
        this.ourPercent = this.percent
        this.startPercent = this.percent
      }
    })
    this.initTrHeight()
  },
  methods: {
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.whitelistForm.scrollHeight)
      })
    },
    addItem() {
      this.form.ipWhiteList.push('')
      this.trAddHeight(this.$refs.row[0].offsetHeight)
    },
    removeItem(i) {
      if (this.form.ipWhiteList.length <= 1) return
      this.form.ipWhiteList.splice(i, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    finishInputPercent() {
      if (validatorDecimal(this.form.percent)) {
        const dt = this.startPercent - this.form.percent
        this.ourPercent = dt
      }
    },
    onSubmitForm() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            const formSender = { ...this.form }
            delete formSender.passwordConfirm
            if (formSender.websiteUrl.indexOf('https://') === -1) {
              formSender.websiteUrl = `https://${formSender.websiteUrl}`
            }
            if (formSender.callbackUrl.indexOf('https://') === -1) {
              formSender.callbackUrl = `https://${formSender.callbackUrl}`
            }
            formSender.credit = parseFloat(formSender.credit)
            // eslint-disable-next-line no-unreachable
            const res = await agentService.postAuthen('', formSender)
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: res.data,
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">

.repeater-form {
  overflow: hidden;
  transition: .35s height !important;
}

.inline-spacing {
  display: flex;
  flex-wrap: wrap;
  text-align: end;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    //margin-left: 1.5rem;
  }
}

</style>
