<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card>
        <b-form @submit.prevent>
          <b-card-text>
            <strong>General Information</strong>
          </b-card-text>
          <b-row class="mt-1">
            <b-col md="6">
              <b-form-group
                label="Prefix"
                label-for="mc-prefix"
              >
                <b-form-input
                  id="mc-prefix"
                  v-model="form.prefix"
                  placeholder="Prefix"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Username"
                label-for="mc-username"
              >
                <b-form-input
                  id="mc-username"
                  v-model="form.username"
                  placeholder="Username"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nickname"
                label-for="mc-nickname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nickname"
                  rules="required"
                >
                  <b-form-input
                    id="mc-nickname"
                    v-model="form.nickName"
                    placeholder="Nickname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Phone"
                label-for="mc-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required|digits:10"
                >
                  <b-form-input
                    id="mc-phone"
                    v-model="form.phone"
                    placeholder="Phone"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="mc-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email"
                >
                  <b-form-input
                    id="mc-email"
                    v-model="form.email"
                    placeholder="Email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.canSendPoy === true"
              md="6"
            >
              <b-form-group
                label="Available Poy Credit"
                label-for="mc-credit"
              >
                <b-form-input
                  id="mc-email"
                  v-model="form.credit"
                  placeholder="Email"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.walletType === 1"
              md="6"
            >
              <b-form-group
                label="Available Credit"
                label-for="mc-credit"
              >
                <b-form-input
                  id="mc-email"
                  v-model="form.credit"
                  placeholder="Email"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-card-text v-if="$can('update', 'api')">
            <strong>Api Information</strong>
          </b-card-text>
          <b-row
            v-if="$can('update', 'api')"
            class="mt-1"
          >
            <b-col md="6">
              <b-form-group
                label="Website URL"
                label-for="mc-website-url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Website URL"
                  rules="required|url"
                >
                  <b-form-input
                    id="mc-website-url"
                    v-model="form.websiteUrl"
                    placeholder="Website URL"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="CallBack URL"
                label-for="mc-callback-url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CallBack URL"
                  rules="required|url"
                >
                  <b-form-input
                    id="mc-callback-url"
                    v-model="form.callbackUrl"
                    placeholder="Callback URL"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="align-self-start"
              style="margin-top: 12.5px"
            >
              <b-form-group
                label="SecretKey"
                label-for="secretKey"
              >
                <b-form-input
                  id="secretKey"
                  v-model="form.secretKey"
                  placeholder="SecretKey"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label>IP Whitelist
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="btn-icon "
                    style="margin-left: 2px"
                    @click="form.ipWhiteList.push('')"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </label>

                <div
                  v-for="(v,i) in form.ipWhiteList"
                  :key="i"
                  class="mb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="IP Whitelist"
                    rules="regex:^\d+\.\d+\.\d+\.\d+$"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="form.ipWhiteList[i]"
                        placeholder="IP Address"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="form.ipWhiteList.splice(i,1)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="onSubmitForm"
          >
            Update Info
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import helper from '@/service/helper'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        prefix: '',
        username: '',
        nickName: '',
        phone: '',
        email: '',
        websiteUrl: '',
        callbackUrl: '',
        ipWhiteList: [''],
      },
    }
  },
  async mounted() {
    const res = await helper.getAuthen('agent/profile')
    if (res.status === 200) {
      this.form = res.data
      if (this.form.ipWhiteList === null) {
        this.form.ipWhiteList = []
      }
    }
  },
  methods: {
    onSubmitForm() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            const formSender = { ...this.form }
            delete formSender.passwordConfirm
            const res = await helper.putAuthen('agent/profile/information', formSender)
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: res.data,
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
    },
  },
}
</script>
