<template>
  <validation-observer ref="simpleRules">
    <b-card
      class="pt-1 pb-1"
      style="max-width: 1200px;"
    >
      <b-form @submit.prevent>
        <b-card-text>
          <h4>Basic Info</h4>
        </b-card-text>
        <b-row class="mt-2 mb-2 align-items-center  pl-lg-5 pr-lg-5 pl-md-2 pr-md-2">
          <b-col
            md="6"
          >
            <b-form-group
              label="Username"
              label-for="mc-username"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="mc-username"
                  v-model="form.username"
                  placeholder="Username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nickname"
              label-for="mc-nickname"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <b-form-input
                id="mc-nickname"
                v-model="form.nickName"
                placeholder="Nickname"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Password"
              label-for="mc-password"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|password"
              >
                <b-form-input
                  id="mc-password"
                  v-model="form.password"
                  placeholder="Password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="$can('update', 'transfer')"
          class="mt-2 mt-md-4 mb-2"
        >
          <b-col
            cols="12"
            md="6"
          >
            <b-card-text>
              <h4>Credit</h4>
            </b-card-text>
            <div class="pl-lg-5 pl-md-2">
              <b-form-group
                label="Your Credit"
                label-for="mc-pt"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <b-form-input
                  id="mc-pt"
                  v-model="creditSetting.credit"
                  placeholder="Credit"
                  readonly
                />
              </b-form-group>

              <b-form-group
                label="Credit"
                label-for="credit"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <validation-provider
                  v-if="creditSetting.isMaster"
                  #default="{ errors }"
                  name="Credit"
                  rules="decimal|min_value:0"
                >
                  <b-form-input
                    id="credit"
                    v-model="form.credit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  v-else
                  #default="{ errors }"
                  name="Credit"
                  :rules="`decimal|min_value:0|max_value:${creditSetting.credit}`"
                >
                  <b-form-input
                    id="credit"
                    v-model="form.credit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <div class="text-center text-sm-left">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="onSubmitForm"
          >
            Submit
          </b-button>
        </div>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import agentService from '@/service/agentService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        username: '',
        nickName: '',
        password: '',
        credit: '',
      },
      creditSetting: {
        load: false,
        isMaster: false,
        credit: 0,
        walletType: 0,
      },
      fields: [
        {
          key: 'index',
          label: 'ลำดับ',
          sortable: true,
        },
        {
          key: 'agent',
          label: 'agent',
          class: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'balance',
          label: 'ยอดเงินล่าสุด',
          class: 'text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v.toFixed(2)),
        },
        {
          key: 'lastLoginAt',
          label: 'LastLogin',
          class: 'text-nowrap',
        },
        {
          key: 'lastLoginIp',
          label: 'LastIP',
          class: 'text-nowrap',
        },
        'delete',
      ],
    }
  },
  async mounted() {
    agentService.getAuthen('wallet-status')
      .then(res => {
        this.creditSetting.load = true
        this.creditSetting.isMaster = res.data.isMaster
        this.creditSetting.credit = res.data.credit
        this.creditSetting.walletType = res.data.walletType
      })
  },
  methods: {
    onSubmitForm() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            this.$swal({
              dark: true,
              title: 'Are you sure?',
              text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(async result => {
                if (result.value) {
                  const formSender = { ...this.form }
                  const res = await agentService.postAuthen('members', formSender)
                  if (res.status === 200) {
                    this.creditSetting.credit = res.data.agentCredit
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'success',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: res.data.msg,
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: res.data,
                      },
                    })
                  }
                }
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
