<template>
  <b-card title="ตั้งค่าอัตราจ่ายพิเศษ">
    <b-form
      class="mb-1"
      @submit.prevent
    >
      <b-row class="mt-1 text-left text-md-center align-items-top align-content-start">
        <b-col
          cols="12"
          md="5"
        >
          <b-form-group
            label="Username"
            label-for="mc-username"
            label-cols-lg="3"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Username"
            >
              <b-form-input
                id="mc-username"
                v-model="form.username"
                placeholder="Username"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <b-form-group
            label="ประเภทหวย"
            label-for="mc-type"
            label-cols-lg="3"
            label-cols-md="4"
          >
            <v-select
              v-model="form.huayName"
              :disabled="form.username === null || form.username.length === 0"
              label="title"
              :options="huayTypes"
              placeholder="ประเภทหวย"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click.prevent="search(false)"
          >
            ค้นหา
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div
      v-if="!loaded"
    >
      <div
        v-if="isInit"
      >
        <LottoRatePerUser
          :huay-type="form.huayType"
          :huay-id="form.huayId"
          :huay-rate="huayRate"
        />

        <LottoConditionPerUser
          class="mt-1"
          :huay-type="form.huayType"
          :huay-id="form.huayId"
          :huay-condition="huayCondition"
        />
        <div class="text-right mt-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="danger"
            @click.prevent="deleteRate"
          >
            <feather-icon icon="TrashIcon" />
            Delete
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormInput, BFormGroup, BForm, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LottoRatePerUser from '@/views/components/LottoRatePerUser.vue'
import LottoConditionPerUser from '@/views/components/LottoConditionPerUser.vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BButton,
    vSelect,
    LottoRatePerUser,
    LottoConditionPerUser,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: true,
      form: {
        username: '',
        huayName: '',
        huayType: 0,
        huayId: 0,
      },
      huayName: '',
      huayCondition: {},
      huayRate: {},
      huayTypes: [],
      huayTypesMapper: {},
      huayIdMapper: {},
      isInit: false,
    }
  },
  async mounted() {
    this.loaded = true
    const { username } = this.$route.query
    this.form.username = username
    const oldType = localStorage.getItem('huayId')
    if (oldType != null) {
      this.form.huayName = oldType
    }
    lottoService
      .getAuthen('huay/dict')
      .then(res => {
        // eslint-disable-next-line no-restricted-syntax
        for (const v of res.data) {
          if (v.type !== 5) {
            this.huayTypes.push(v.name)
            this.huayIdMapper[v.name] = v.id
            this.huayTypesMapper[v.name] = v.type
          }
        }
        this.huayTypes.push('หวยยี่กี')
        this.huayIdMapper['หวยยี่กี'] = 'yeekee'
        if (username) {
          this.search(true)
        }
      })
  },
  methods: {
    deleteRate() {
      if (!this.isInit) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: 'ระบบขัดคล่อง โปรดลองใหม่อีกครั้งค่ะ',
          },
        })
        return
      }
      this.$swal({
        dark: true,
        title: 'ยืนยัน',
        text: `คุณต้องการ ลบ อัตราจ่ายพิเศษ ${this.form.huayName} สำหรับ ${this.form.username} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.form.huayId = this.huayIdMapper[this.form.huayName]
            const res = await lottoService.deleteAuthen(`rate-user/delete/${this.form.huayId}?username=${this.form.username}`)
            if (res.status === 200) {
              this.isInit = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'ลบข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: 'ระบบขัดคล่อง โปรดลองใหม่อีกครั้งค่ะ',
                },
              })
            }
          }
        })
    },
    async search(firstLoad) {
      if (this.form.username == null || this.form.username === '') {
        if (!firstLoad) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: 'คุณยังไม่ได้กรอก username',
            },
          })
        }
        return
      }
      if (this.form.huayName == null || this.form.huayName === '') {
        if (!firstLoad) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: 'คุณยังไม่ได้เลือกประเภทหวย',
            },
          })
        }
        return
      }

      this.loaded = true
      this.form.huayId = this.huayIdMapper[this.form.huayName]
      this.form.huayType = this.huayTypesMapper[this.form.huayName]
      localStorage.setItem('huayId', this.form.huayName)
      const res = await lottoService.getAuthen(`rate-user/condition/${this.form.huayId}?username=${this.form.username}`)
      setTimeout(async () => {
        if (res.status === 200) {
          this.isInit = true
          this.huayCondition = res.data
          this.huayRate = res.data
        } else {
          this.isInit = false
          if (!firstLoad) {
            this.$swal({
              dark: true,
              title: 'ยืนยัน',
              text: `คุณยังไม่เคยตั้งค่า อัตราจ่ายพิเศษ ${this.form.huayName} สำหรับ ${this.form.username} ต้องการตั้งค่าใช่หรือไม่`,
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(async result => {
                if (result.value) {
                  const res2 = await lottoService.getAuthen(`rate-user/init/${this.form.huayId}?username=${this.form.username}`)
                  if (res2.status === 200) {
                    const res3 = await lottoService.getAuthen(`rate-user/condition/${this.form.huayId}?username=${this.form.username}`)
                    if (res3.status === 200) {
                      this.isInit = true
                      this.huayCondition = res3.data
                      this.huayRate = res3.data
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Error',
                          icon: 'AlertCircleIcon',
                          variant: 'danger',
                          text: 'ระบบขัดคล่อง โปรดลองใหม่อีกครั้งค่ะ',
                        },
                      })
                    }
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: 'ระบบขัดคล่อง โปรดลองใหม่อีกครั้งค่ะ',
                      },
                    })
                  }
                }
              })
          }
        }
        this.loaded = false
      }, 100)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.custom-control-pink .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-pink .custom-control-input:active ~ .custom-control-label::before {
  border-color: hotpink !important;
  background-color: hotpink !important;
  box-shadow: 0 2px 4px 0 rgba(255, 192, 203, 0.4) !important;
}

.custom-control-yellow .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-yellow .custom-control-input:active ~ .custom-control-label::before {
  border-color: rgba(235, 178, 67, 0.99) !important;
  background-color: rgba(235, 178, 67, 0.99) !important;
  box-shadow: 0 2px 4px 0 rgba(255, 178, 67, 0.4) !important;
}

</style>
