<template>
  <div>
    <b-form-checkbox
      v-if="!$store.state.app.isMaster($store)"
      v-model="isAutoUpdateLimitNumber"
      name="check-button"
      class="mb-2"
      switch
      inline
      @change="changeAutoUpdateLimitNumber"
    >
      Auto Update Limit
    </b-form-checkbox>
    <b-card
      style="max-width: 820px"
    >
      <b-card-text>
        <b-row
          class="justify-content-start align-items-center  text-center text-sm-left"
          no-gutters
        >
          <b-col
            sm="auto"
          >
            <span style="font-size: 1.286rem">อั้นตัวเลข {{ huayData.name }}</span>
          </b-col>
          <b-col
            v-if="!$store.state.app.isMaster($store)"
            sm="auto"
            class="mt-50 mt-sm-0 text-center"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="relief-primary"
              class="ml-50"
              @click="DownloadDefault"
            >
              <span>Default Setting</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-form-group
            label="ประเภทการเเทง"
            label-for="mc-type"
          >
            <v-select
              v-model="form.type"
              label="ประเภทการเเทง"
              :clearable="false"
              :options="options"
              class="mb-1"
              append-to-body
              placeholder="ประเภทการเเทง"
            />
          </b-form-group>
          <b-form-group
            label="เลข"
            label-for="mc-number"
          >
            <validation-provider
              #default="{ errors }"
              name="number"
              :rules="`required|digits:${numberLimit(form.type)}`"
            >
              <b-form-input
                id="mc-number"
                v-model="form.number"
                :disabled="!form.type"
                placeholder="เลข"
                :minlength="numberLimit(form.type)"
                :maxlength="numberLimit(form.type)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="เปอร์เซ็นที่จะจ่าย (Ex.50)"
            label-for="percent"
          >
            <validation-provider
              #default="{ errors }"
              name="percent"
              rules="required|decimal|min_value:0|max_value:100"
            >
              <b-form-input
                id="percent"
                v-model="form.multiply"
                :disabled="!form.type"
                type="number"
                placeholder="เปอร์เซ็นที่จะจ่าย (Ex.50)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="primary"
              class="mb-1"
              type="submit"
              @click.prevent="onSubmit"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <b-card-text><strong>รายการเลขอั้นทั้งหมด</strong></b-card-text>
      <b-table
        responsive
        :fields="fields"
        :items="limits"
      >
        <template #cell(type)="data">
          {{ options[data.value - 1] }}
        </template>
        <template #cell(number)="data">
          <div
            class="hintEdit"
            @click="editData(data.index,'number')"
          >
            {{ data.value }}
          </div>
        </template>
        <template #cell(multiply)="data">
          <div
            class="hintEdit"
            @click="editData(data.index,'multiply')"
          >
            {{ data.value }}
          </div>
        </template>
        <template #cell(action)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon rounded-circle m-0"
            size="sm"
            @click="deleteItem(data.item.id)"
          >
            <feather-icon icon="ArchiveIcon" />
          </b-button>
        </template>
      </b-table>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="dark"
        class="mb-50 mr-50"
        @click="$router.go(-1)"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          class="mr-25"
        />
        <span>ย้อนกลับ</span>
      </b-button>

      <b-button
        v-if="$store.state.app.isMaster($store)"
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="relief-primary"
        class="ml-50 mb-50"
        @click="BroadcastUpdate"
      >
        <span>Broadcast Update</span>
      </b-button>

      <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="relief-danger"
        class="ml-50 mb-50"
        @click="deleteAll"
      >
        <span class="ml-25">Delete All</span>
      </b-button>
    </b-card>

    <b-modal
      v-model="showModal"
      cancel-variant="secondary"
      ok-only
      ok-title="Ok"
      centered
      size="xs"
      title="เเก้ไข"
      button-size="sm"
      @ok="submitEdit"
      @hidden="modalTextError=''"
    >
      <b-form-input
        v-model="modalText"
        autofocus
        type="number"
        @keypress.enter="submitEdit"
      />
      <small
        v-if="modalTextError !== ''"
        class="text-danger"
      >{{ modalTextError }}</small>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components,no-unused-vars */
import {
  BForm, BFormInput, BRow, BCol, BButton, BCard, BFormGroup, BTable, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'

const { BFormCheckbox } = require('bootstrap-vue')

export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isAutoUpdateLimitNumber: false,
      loaded: true,
      options: [
        'สามตัวบน',
        'สามตัวโต้ด',
        'สามตัวหน้า',
        'สามตัวท้าย',
        'สองตัวบน',
        'สองตัวล่าง',
        'วิ่งบน',
        'วิ่งล่าง',
      ],
      mapValue: {
        สามตัวบน: 1,
        สามตัวโต้ด: 2,
        สามตัวหน้า: 3,
        สามตัวท้าย: 4,
        สองตัวบน: 5,
        สองตัวล่าง: 6,
        วิ่งบน: 7,
        วิ่งล่าง: 8,
        เลขชุดสี่ตัว: 9,
      },
      mapValueLimit: {
        สามตัวบน: 3,
        สามตัวโต้ด: 3,
        สามตัวหน้า: 3,
        สามตัวท้าย: 3,
        สองตัวบน: 2,
        สองตัวล่าง: 2,
        วิ่งบน: 1,
        วิ่งล่าง: 1,
        เลขชุดสี่ตัว: 4,
      },
      huayData: {
        name: '',
      },
      form: {
        huayId: '',
        type: null,
        number: '',
        multiply: '',
      },
      loading: false,
      pagination: {
        rowsPerPage: 0,
      },
      fields: [
        {
          key: 'type',
          label: 'ประเภทการเเทง',
          class: 'text-nowrap',
        },
        {
          key: 'number',
          label: 'ตัวเลข',
        },
        {
          key: 'multiply',
          label: 'เปอร์เซ็นจ่าย',
          class: 'text-center text-nowrap',
        },
        {
          key: 'action',
          label: 'กระทำ',
          class: 'text-center',
        },
      ],
      limits: [],
      selectionRows: null,
      focusableObj: {
        row: 0,
        key: '',
        type: 0,
      },
      showModal: false,
      modalText: '',
      modalTextError: '',
    }
  },
  async mounted() {
    const { id } = this.$route.query
    if (id) {
      this.form.huayId = id

      if (!this.$store.state.app.isMaster(this.$store)) {
        lottoService.getAuthen(`limit-number/auto/${id}`)
          .then(res => {
            if (res.status === 200) {
              this.isAutoUpdateLimitNumber = res.data
            }
          })
        setTimeout(() => {
          lottoService.getAuthen(`limit-number/auto/${id}`)
            .then(res => {
              if (res.status === 200) {
                this.isAutoUpdateLimitNumber = res.data
              }
            })
        }, 5000)
      }

      this.loaded = false
      const [res] = await Promise.all([
        lottoService.getAuthen(`huay/label/${id}`),
        this.reloadTable(),
      ])

      if (res.status === 200) {
        this.huayData = res.data.huay
      }
    }
  },
  methods: {
    editData(row, key) {
      this.showModal = true
      this.focusableObj.row = row
      this.focusableObj.key = key
      this.modalText = this.limits[row][key]
      this.focusableObj.type = this.limits[row].type
    },
    submitEdit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.modalText * 1 < 0) {
        this.modalTextError = 'ตัวเลขต้องมีค่าตั้งเเต่ 0 ขึ้นไป'
      } else if (this.modalText.length > 0 && this.modalText[0] === '-') {
        this.modalTextError = 'กรุณากรอกตัวเลขให้ถูกต้อง'
      } else if (this.focusableObj.key === 'number' && this.modalText.length !== this.numberLimit(this.options[this.focusableObj.type - 1])) {
        this.modalTextError = 'กรุณากรอกตัวเลขให้ถูกต้อง'
      } else if (this.focusableObj.key === 'multiply' && (this.modalText * 1 < 0 || this.modalText * 1 > 100)) {
        this.modalTextError = 'กรุณากรอกตัวเลขให้ถูกต้อง'
      } else {
        const {
          row,
          key,
        } = this.focusableObj

        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการเเก้ไขข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          onOpen: () => {
            const btn = this.$swal.getConfirmButton()
            window.addEventListener('keypress', e => {
              if (e.key === 'Enter') {
                btn.click()
              }
            })
          },
        })
          .then(async result => {
            if (result.isConfirmed) {
              const form = { ...this.limits[row] }
              form.multiply *= 1
              if (this.focusableObj.key === 'number') {
                form.number = this.modalText
              } else {
                form.multiply = this.modalText * 1
              }
              const res = await lottoService.putAuthen(`limit/${form.id}`, form)
              if (res.status === 200) {
                this.limits[row][key] = this.modalText
                this.showModal = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ดำเนินการสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: res.data,
                  },
                })
              }
            }
          })
      }
    },
    changeAutoUpdateLimitNumber(e) {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการดำเนินการใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.isConfirmed) {
            const res = await lottoService.getAuthen(`limit-number/${this.form.huayId}`)
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'ดำเนินการสำเร็จ',
                },
              })
            } else {
              this.isAutoUpdateLimitNumber = !e
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          } else {
            this.isAutoUpdateLimitNumber = !e
          }
        })
    },
    async BroadcastUpdate() {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการเเจ้งอัพเดตข้อมูลไปที่ตัวเเทนทั้งหมดใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const res = await lottoService.getAuthen(`limit-number/broadcast/${this.form.huayId}`)
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'ดำเนินการสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
    },
    async reloadTable() {
      this.loading = true
      const res = await lottoService.getAuthen(`limit/${this.form.huayId}`)
      if (res.status === 200) {
        this.loading = false
        this.limits = res.data
      }
    },
    numberLimit(type) {
      return this.mapValueLimit[type]
    },
    async onSubmit() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            const form = { ...this.form }
            // eslint-disable-next-line no-unreachable
            form.type = this.mapValue[this.form.type]
            form.multiply *= 1
            const res = await lottoService.postAuthen('limit', form)
            if (res.status === 200) {
              this.reloadTable()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'เพิ่มข้อมูลสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                },
              })
            }
          }
        })
    },
    async editItem(row) {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const res = await lottoService.putAuthen(`limit/${row.id}`, row)
            if (res.status === 200) {
              this.reloadTable()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'เเก้ไขข้อมูลสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                },
              })
            }
          }
        })
    },
    async deleteItem(id) {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการลบข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const res = await lottoService.deleteAuthen(`limit/${id}`)
            if (res.status === 200) {
              this.reloadTable()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'ลบข้อมูลสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                },
              })
            }
          }
        })
    },
    async deleteAll() {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการลบข้อมูลทั้งหมดใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const res = await lottoService.deleteAuthen(`limit?huayId=${this.form.huayId}`)
            if (res.status === 200) {
              this.reloadTable()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'ลบข้อมูลสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                },
              })
            }
          }
        })
    },
    async DownloadDefault() {
      if (this.form.huayId === '') {
        return
      }
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการใช้การตั้งค่าพื้นฐานใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.loaded = true
            const res = await lottoService.getAuthen(`limit/load-limit-number/${this.form.huayId}`)
            setTimeout(() => {
              if (res.status === 200) {
                this.loading = false
                this.limits = res.data
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ดำเนินการสำเร็จ',
                  },
                })
              } else if (res.status === 422) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ดำเนินการสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'ขออภัย เกิดความผิดพลาดบางอย่าง โปรดลองใหม่อีกครั้งในภายหลัง',
                  },
                })
              }
              this.loaded = false
            }, 100)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
