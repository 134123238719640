<template>
  <validation-observer ref="simpleRules">
    <b-card
      class="pt-1 pb-1"
      style="max-width: 1200px;"
    >
      <b-form @submit.prevent>
        <b-card-text>
          <h4>General Information</h4>
        </b-card-text>
        <b-row class="mt-2 mb-2 align-items-center  pl-lg-5 pr-lg-5 pl-md-2 pr-md-2">
          <b-col
            md="6"
          >
            <b-form-group
              label="Username"
              label-for="mc-username"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="mc-username"
                  v-model="form.username"
                  placeholder="Username"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nickname"
              label-for="mc-nickname"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Nickname"
                rules="required"
              >
                <b-form-input
                  id="mc-nickname"
                  v-model="form.nickName"
                  placeholder="Nickname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="รหัสผ่าน"
              label-for="mc-nickname"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="modalPassword = true"
              >
                เเก้ไขรหัสผ่าน
              </b-button>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Phone"
              label-for="mc-phone"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required|digits:10"
              >
                <b-form-input
                  id="mc-phone"
                  v-model="form.phone"
                  placeholder="Phone"
                  minlength="10"
                  maxlength="10"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="canUpdateWebApi"
              label="Website URL"
              label-for="mc-website-url"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Website URL"
                rules="required|url"
              >
                <b-form-input
                  id="mc-website-url"
                  v-model="form.websiteUrl"
                  placeholder="Website URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="canUpdateWebApi && form.walletType === 0"
              label="CallBack URL"
              label-for="mc-callback-url"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="CallBack URL"
                rules="required|url"
              >
                <b-form-input
                  id="mc-callback-url"
                  v-model="form.callbackUrl"
                  placeholder="Callback URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$can('update', 'poy')"
            md="6"
          >
            <b-form-group
              label="Send Poy"
              label-for="mc-phone"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <div class="inline-spacing">
                <b-form-radio
                  v-model="form.canSendPoy"
                  class="custom-control-primary"
                  :value="true"
                >
                  {{ $t('yes') }}
                </b-form-radio>
                <b-form-radio
                  v-model="form.canSendPoy"
                  class="custom-control-secondary ml-1"
                  :value="false"
                >
                  {{ $t('no') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$can('update', 'agent')"
            md="6"
          >
            <b-form-group
              label="Create Agent"
              label-for="mc-phone"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <div class="inline-spacing">
                <b-form-radio
                  v-model="form.canCreateAgent"
                  class="custom-control-primary"
                  :value="true"
                >
                  {{ $t('yes') }}
                </b-form-radio>
                <b-form-radio
                  v-model="form.canCreateAgent"
                  class="custom-control-secondary ml-1"
                  :value="false"
                >
                  {{ $t('no') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$can('update', 'poy')"
            md="6"
          >
            <b-form-group
              label="Web Api"
              label-for="mc-phone"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <div class="inline-spacing">
                <b-form-radio
                  v-model="form.canWebApi"
                  class="custom-control-primary"
                  :value="true"
                >
                  {{ $t('yes') }}
                </b-form-radio>
                <b-form-radio
                  v-model="form.canWebApi"
                  class="custom-control-secondary ml-1"
                  :value="false"
                >
                  {{ $t('no') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$can('update', 'payRate')"
            md="6"
          >
            <b-form-group
              label="Custom Pay Rate"
              label-for="mc-phone"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <div class="inline-spacing">
                <b-form-radio
                  v-model="form.canCustomPayRate"
                  class="custom-control-primary"
                  :value="true"
                >
                  {{ $t('yes') }}
                </b-form-radio>
                <b-form-radio
                  v-model="form.canCustomPayRate"
                  class="custom-control-secondary ml-1"
                  :value="false"
                >
                  {{ $t('no') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2">
          <b-col
            cols="12"
            md="6"
          >
            <b-card-text>
              <h4>Credit Setting</h4>
            </b-card-text>
            <div
              v-if="$can('update', 'agent')"
              class="pl-lg-5 pl-md-2"
            >
              <b-form-group
                label="Wallet Type"
                label-for="mc-phone"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <div class="inline-spacing">
                  <b-form-radio
                    v-model="form.walletType"
                    class="custom-control-primary"
                    :value="0"
                  >
                    Single
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.walletType"
                    class="custom-control-info ml-1"
                    :value="1"
                  >
                    Transfer
                  </b-form-radio>
                </div>
              </b-form-group>
            </div>
            <div
              class="pl-lg-5 pl-md-2"
            >
              <b-form-group
                v-if="form.walletType === 0"
                label="Our PT(%)"
                label-for="our-pt"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <b-form-input
                  id="mc-our-pt"
                  v-model="ourPercent"
                  placeholder="our percent"
                  disabled
                />
              </b-form-group>
              <div
                v-if="form.walletType === 0"
                role="group"
                class="form-row form-group mr-lg-3"
              >
                <div
                  class="col-md-4 col-form-label"
                >
                  Given PT(%)
                </div>
                <div
                  class="col"
                >
                  <b-row class="align-items-center justify-content-start no-gutters">
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-input
                        v-model="form.percent"
                        disabled
                        type="number"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="8"
                      class="pl-md-1"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="text-nowrap"
                        @click.prevent="showPercentForm"
                      >
                        Edit Given PT
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>

              <b-form-group
                v-if="form.walletType === 1"
                label="Credit"
                label-for="mc-pt"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <b-form-input
                  id="mc-pt"
                  v-model="form.credit"
                  type="number"
                  disabled
                  placeholder="Credit"
                />
              </b-form-group>
              <div
                v-if="$can('update', 'poy') && form.canSendPoy"
                role="group"
                class="form-row form-group mr-lg-3"
              >
                <div
                  class="col-md-4 col-form-label"
                >
                  Credit Poy
                </div>
                <div
                  class="col"
                >
                  <b-row class="align-items-center justify-content-start no-gutters">
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <b-form-input
                        v-model="form.partnerCredit"
                        disabled
                        type="number"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="pl-md-1"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="text-nowrap"
                        @click.prevent="showCreditPoyForm"
                      >
                        Edit
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>

            </div>

          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-card-text class="mr-5 mt-1 mt-md-0">
              <h4>Status & WhiteList</h4>
            </b-card-text>
            <div
              class="pr-lg-5 pr-md-2"
            >
              <b-form-group
                label="Suspend"
                label-for="Suspend"
                label-cols-md="4"
                class="ml-lg-3"
              >
                <b-row class="align-items-center justify-content-start no-gutters">
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-input
                      disabled
                      :value="isEnable === true ? 'No' : 'Yes'"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="8"
                    class="pl-md-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="text-nowrap"
                      @click.prevent="submitStatus"
                    >
                      Edit Status
                    </b-button>
                  </b-col>
                </b-row>

              </b-form-group>
            </div>
            <div
              v-show="canUpdateWebApi"
              role="group"
              class="form-row form-group ml-lg-3 "
            >
              <div class="col-md-4 col-form-label">IP Whitelist
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="btn-icon "
                  @click="addItem()"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
              <div
                ref="whitelistForm"
                class="col mt-25 repeater-form"
                :style="{height: trHeight}"
              >
                <div
                  v-for="(v,i) in form.ipWhiteList"
                  :key="i"
                  ref="row"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="form.ipWhiteList[i]"
                      placeholder="IP Address"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="removeItem(i)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="text-center text-sm-left">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="onSubmitForm"
          >
            Submit
          </b-button>
        </div>
      </b-form>
    </b-card>

    <b-modal
      v-model="modalPassword"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      title="เปลี่ยนรหัสผ่าน"
      button-size="sm"
      @ok="submitPassword"
    >
      <b-form @submit.prevent>
        <b-form-group
          label="Password"
          label-for="mc-password"
        >
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="Password"
            rules="required|min:8|password"
          >
            <b-form-input
              id="mc-password"
              v-model="passwordForm.password"
              autofocus
              placeholder="Password"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Confirm Password"
          label-for="mc-confirm-password"
        >
          <validation-provider
            #default="{ errors }"
            name="Confirm Password"
            rules="required|confirmed:Password"
          >
            <b-form-input
              id="mc-confirm-password"
              v-model="passwordForm.passwordConfirm"
              :state="errors.length > 0 ? false:null"
              placeholder="Confirm Password"
              @keydown.enter="submitPassword"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      v-model="modalPercent"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      title="Edit Commission"
      button-size="sm"
      @ok="submitPercent"
    >
      <b-form @submit.prevent>
        <b-form-group
          label="Our PT(%)"
          label-for="our-pt"
          label-cols-md="4"
          class="mr-lg-3"
        >
          <b-form-input
            id="our-pt"
            v-model="percentForm.ourPercent"
            placeholder="our percent"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Given PT(%)"
          label-for="mc-pt"
          label-cols-md="4"
          class="mr-lg-3"
        >
          <validation-provider
            #default="{ errors }"
            name="Given PT(%)"
            rules="required|min_value:1|max_value:100"
          >
            <b-form-input
              id="mc-pt"
              v-model="percentForm.percent"
              type="number"
              placeholder="Given PT(%)"
              autofocus
              @input="finishInputPercent"
              @keydown.enter="submitPercent"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      v-model="modalPoyCredit"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      title="Edit Credit Poy"
      button-size="sm"
      @ok="submitCreditPoy"
    >
      <b-form @submit.prevent>
        <b-form-group
          label="Credit Poy"
          label-for="mc-pt"
          label-cols-md="4"
          class="mr-lg-3"
        >
          <validation-provider
            #default="{ errors }"
            name="Credit"
            rules="decimal|min_value:0"
          >
            <b-form-input
              id="mc-pt"
              v-model="creditPoyForm.credit"
              type="number"
              placeholder="Credit"
              autofocus
              @keydown.enter="submitCreditPoy"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BInputGroup,
  BInputGroupAppend,
  BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import agentService from '@/service/agentService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    canUpdateWebApi() {
      return this.$can('update', 'api') && this.$can('update', 'poy') && this.form.canWebApi
    }
  },
  data() {
    return {
      percent: null,
      ourPercent: null,
      form: {},
      isEnable: false,

      modalPassword: false,
      passwordForm: {
        password: '',
        passwordConfirm: '',
      },

      modalPercent: false,
      percentForm: {
        ourPercent: null,
        percent: null,
      },
      creditPoyForm: {
        credit: 0,
      },
      modalPoyCredit: false,
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  async mounted() {
    const username = this.$router.currentRoute.query.id
    if (username !== null && username !== undefined) {
      const [res, res2] = await Promise.all([agentService.getAuthen(`find?username=${username}`), agentService.getAuthen(`percent`)])
      if (res.status === 200) {
        this.form = res.data
        this.isEnable = res.data.isEnable
        if (this.form.ipWhiteList === null) {
          this.form.ipWhiteList = []
        }
        if (this.$store.state.app.isMaster(this.$store)) {
          if (res.data.password !== '') {
            this.passwordForm.password = res.data.password
          }
        }
      }
      if (res2.status === 200) {
        if (res2.status === 200) {
          this.ourPercent = this.percent - this.form.percent
        } else {
          this.ourPercent = this.percent
        }
      }
    }

    this.initTrHeight()
  },
  methods: {
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.whitelistForm.scrollHeight)
      })
    },
    addItem() {
      this.form.ipWhiteList.push('')
      this.trAddHeight(this.$refs.row[0].offsetHeight)
    },
    removeItem(i) {
      if (this.form.ipWhiteList.length <= 1) return
      this.form.ipWhiteList.splice(i, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    finishInputPercent() {
      const dt = this.form.percent - this.percentForm.percent
      this.percentForm.ourPercent = this.ourPercent + dt
    },
    onSubmitForm() {
      this.$refs.simpleRules.validate()
          .then(async success => {
            if (success) {
              this.$swal({
                dark: true,
                title: 'Are you sure?',
                text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
                  .then(async result => {
                    if (result.value) {
                      let checkValidIp = true
                      const listIp = []
                      for (let i = 0; i < this.form.ipWhiteList.length; i++) {
                        const ip = this.form.ipWhiteList[i]
                        if (ip !== '') {
                          checkValidIp = /^\d+\.\d+\.\d+\.\d+$/.test(ip)
                          listIp.push(ip)
                        }
                      }
                      if (checkValidIp === false) {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: 'please enter correct ip address',
                          },
                        })
                        return
                      }

                      this.form.ipWhiteList = listIp
                      this.initTrHeight()

                      const formSender = { ...this.form }
                      const res = await agentService.putAuthen(`?username=${this.form.username}`, formSender)
                      if (res.status === 200) {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'success',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: res.data,
                          },
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data,
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
    submitStatus() {
      this.$refs.simpleRules.validate()
          .then(async success => {
            if (success) {
              this.$swal({
                dark: true,
                title: 'Lock / Unlock Account',
                text: 'คุณเเน่ใจว่าต้องการดำเนินการใช่หรือไม่ ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
                  .then(async result => {
                    if (result.value) {
                      const res = await agentService.putAuthen(`toggle-status?username=${this.form.username}`)
                      if (res.status === 200) {
                        this.isEnable = res.data
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'success',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: 'ดำเนินการสำเร็จ',
                          },
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data,
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
    submitPassword(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate()
          .then(async success => {
            if (success) {
              this.$swal({
                dark: true,
                title: 'Are you sure?',
                text: 'คุณเเน่ใจว่าต้องการเปลี่ยนรหัสผ่านใช่หรือไม่',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
                  .then(async result => {
                    if (result.value) {
                      const formSender = { ...this.passwordForm }
                      delete formSender.passwordConfirm

                      const res = await agentService.putAuthen(`password?username=${this.form.username}`, formSender)
                      if (res.status === 200) {
                        this.modalPassword = false
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'success',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: res.data,
                          },
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data,
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
    showPercentForm() {
      this.percentForm.ourPercent = this.ourPercent
      this.percentForm.percent = this.form.percent
      this.modalPercent = true
    },
    showCreditForm() {
      this.creditForm.credit = this.form.credit + ''
      this.modalCredit = true
    },
    showCreditPoyForm() {
      this.creditPoyForm.credit = this.form.partnerCredit + ''
      this.modalPoyCredit = true
    },
    submitPercent(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate()
          .then(async success => {
            if (success) {
              this.$swal({
                dark: true,
                title: 'Are you sure?',
                text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
                  .then(async result => {
                    if (result.value) {
                      const percentForm = { ...this.percentForm }
                      delete percentForm.ourPercent

                      const res = await agentService.putAuthen(`edit-percent?username=${this.form.username}`, percentForm)
                      if (res.status === 200) {
                        this.modalPercent = false
                        this.ourPercent = this.percent - res.data
                        this.form.percent = res.data
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'success',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: 'บันทึกข้อมูลสำเร็จ',
                          },
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: 'sorry , something wrong please try again',
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
    submitCredit(e) {
      e.preventDefault()
      let keydownEvent = (e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          this.$swal.clickConfirm()
        } else if (e.key === 'Escape') {
          this.$swal.close()
        }
      }
      this.$refs.simpleRulesCredit.validate()
          .then(async success => {
            if (success) {
              this.$swal({
                dark: true,
                title: 'Are you sure?',
                text: `คุณต้องการอัพเดตเครดิตเป็น ${this.creditForm.credit} ใช่หรือไม่`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                allowEnterKey: true,
                focusConfirm: true,
                inputAutoFocus: true,
                onOpen: () => {
                  document.addEventListener('keydown', keydownEvent)
                },
                onClose: () => {
                  document.removeEventListener('keydown', keydownEvent)
                }
              })
                  .then(async result => {
                    if (result.value) {
                      const form = {
                        credit: parseFloat(this.creditForm.credit)
                      }

                      const res = await agentService.putAuthen(`edit-credit?username=${this.form.username}`, form)
                      if (res.status === 200) {
                        this.modalCredit = false
                        this.form.credit = res.data.agentCredit + ''
                        this.creditSetting.credit = `${res.data.ownerCredit}`
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'success',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: 'ดำเนินการสำเร็จ',
                          },
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: 'sorry , something wrong please try again',
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
    submitCreditPoy(e) {
      e.preventDefault()
      let keydownEvent = (e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          this.$swal.clickConfirm()
        } else if (e.key === 'Escape') {
          this.$swal.close()
        }
      }
      this.$refs.simpleRules.validate()
          .then(async success => {
            if (success) {
              this.$swal({
                dark: true,
                title: 'Are you sure?',
                text: `คุณต้องการอัพเดตเครดิตเป็น ${this.creditPoyForm.credit} ใช่หรือไม่`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                allowEnterKey: true,
                focusConfirm: true,
                inputAutoFocus: true,
                onOpen: () => {
                  document.addEventListener('keydown', keydownEvent)
                },
                onClose: () => {
                  document.removeEventListener('keydown', keydownEvent)
                }
              })
                  .then(async result => {
                    if (result.value) {
                      const form = {
                        credit: parseFloat(this.creditPoyForm.credit)
                      }

                      const res = await agentService.putAuthen(`edit-credit-poy?username=${this.form.username}`, form)
                      if (res.status === 200) {
                        this.modalPoyCredit = false
                        this.form.partnerCredit = res.data
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'success',
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: 'อัพเดตเครดิตสำเร็จ',
                          },
                        })
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Error',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: 'sorry , something wrong please try again',
                          },
                        })
                      }
                    }
                  })
            }
          })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height !important;
}

.inline-spacing {
  display: flex;
  flex-wrap: wrap;
  text-align: end;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    //margin-left: 1.5rem;
  }
}
</style>
