<template>
  <div>
    <b-card-text><strong>เงื่อนไขการเเทง</strong></b-card-text>
    <b-table
      responsive
      bordered
      :fields="fields"
      :items="items"
    >
      <template #cell(type)="data">
        {{ options[data.value - 1] }}
      </template>
      <template #cell(min)="data">
        <div
          class="hintEdit"
          @click="editData(data.index,'min')"
        >
          {{ data.value }}
        </div>
      </template>
      <template #cell(maxPerTime)="data">
        <div
          class="hintEdit"
          @click="editData(data.index,'maxPerTime')"
        >
          {{ data.value }}
        </div>
      </template>
      <template #cell(maxPerNumber)="data">
        <div
          class="hintEdit"
          @click="editData(data.index,'maxPerNumber')"
        >
          {{ data.value }}
        </div>
      </template>
    </b-table>

    <div class="text-right">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mt-1"
        @click.prevent="saveEdit"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Submit</span>
      </b-button>
    </div>

    <b-modal
      v-model="showModal"
      cancel-variant="secondary"
      ok-only
      ok-title="Ok"
      centered
      size="xs"
      title="เเก้ไข"
      button-size="sm"
      @ok="submitEdit"
      @hidden="modalTextError=''"
    >
      <b-form-input
        v-model="modalText"
        autofocus
        type="number"
        @keypress.enter="submitEdit"
      />
      <small
        v-if="modalTextError !== ''"
        class="text-danger"
      >{{ modalTextError }}</small>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable dot-notation */

import {
  BButton, BCardText, BFormInput, BModal, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BButton,
    BModal,
    BFormInput,
    BCardText,
  },
  directives: {
    Ripple,
  },
  // props: ['huayData', 'huayCondition'],
  props: {
    huayData: {
      type: Object,
      default: null,
    },
    huayCondition: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      options: [
        'สามตัวบน',
        'สามตัวโต้ด',
        'สามตัวหน้า',
        'สามตัวท้าย',
        'สองตัวบน',
        'สองตัวล่าง',
        'วิ่งบน',
        'วิ่งล่าง',
        // 'เลขชุดสี่ตัว',
        'สี่ตัวท้าย',
        'ห้าตัวท้าย',
        'หกตัว',
      ],
      fields: [
        {
          key: 'type',
          label: 'ประเภท',
          class: 'text-nowrap text-center',
        },
        {
          key: 'min',
          label: 'แทงขั้นต่ำต่อครั้ง',
          class: 'text-center text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v),
        },
        {
          key: 'maxPerTime',
          label: 'เเทงสูงสุดต่อครั้ง',
          class: 'text-center text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v),
        },
        {
          key: 'maxPerNumber',
          label: 'แทงสูงสุดต่อเลข',
          class: 'text-center  text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v),
        },
      ],
      items: [],
      focusableObj: {
        row: 0,
        key: '',
      },
      showModal: false,
      modalText: '',
      modalTextError: '',
    }
  },
  mounted() {
    if (this.huayData.type === 5) {
      this.options = [{
        name: 'min',
        label: 'จำนวนเงิน',
        field: 'min',
        align: 'center',
      },
      {
        name: 'maxPerTime',
        label: 'จำนวนชุดมากสุดต่อครั้ง',
        field: 'maxPerTime',
        align: 'center',
      },
      {
        name: 'maxPerNumber',
        label: 'จำนวนชุดมากสุดต่อเลข',
        field: 'maxPerNumber',
        align: 'center',
      }]
      this.fields = [
        {
          key: 'min',
          label: 'จำนวนเงิน',
          class: 'text-center',
          formatter: v => this.$store.state.app.toNumber(v),
        },
        {
          key: 'maxPerTime',
          label: 'จำนวนชุดมากสุดต่อครั้ง',
          class: 'text-center',
          formatter: v => this.$store.state.app.toNumber(v),
        },
        {
          key: 'maxPerNumber',
          label: 'จำนวนชุดมากสุดต่อเลข',
          class: 'text-center',
          formatter: v => this.$store.state.app.toNumber(v),
        },
      ]
      this.items = [this.huayCondition[0]]
    } else {
      this.items = this.huayCondition
      // eslint-disable-next-line no-restricted-syntax
      // for (const v of this.items) {
      //   // eslint-disable-next-line no-underscore-dangle
      //   v._cellVariants = {
      //     maxPerNumber: 'info',
      //   }
      //   // eslint-disable-next-line no-underscore-dangle
      //   // v._rowVariant = 'danger'
      // }
    }
  },
  methods: {
    editData(row, key) {
      this.showModal = true
      this.focusableObj.row = row
      this.focusableObj.key = key
      this.modalText = this.items[row][key]
    },
    submitEdit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.modalText * 1 < 0) {
        this.modalTextError = 'ตัวเลขต้องมีค่าตั้งเเต่ 0 ขึ้นไป'
      } else {
        const {
          row,
          key,
        } = this.focusableObj
        this.items[row][key] = this.modalText
        this.showModal = false
      }
    },
    saveEdit() {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              // eslint-disable-next-line no-restricted-syntax
              for (const v of this.huayCondition) {
                v.min *= 1
                v.maxPerTime *= 1
                v.maxPerNumber *= 1
              }
              const res = await lottoService.putAuthen(
                `rate/condition/${id}`,
                this.huayCondition,
              )
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'บันทึกข้อมูสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: res.data,
                  },
                })
              }
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
