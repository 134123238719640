export default [
  {
    header: 'Credit',
    resource: 'transfer',
    action: ['read', 'update'],
  },
  {
    title: 'Agent Credit',
    icon: 'CreditCardIcon',
    route: 'credit-agent',
    resource: 'transfer',
    action: ['read', 'update'],
  },
  {
    title: 'User Credit',
    icon: 'DollarSignIcon',
    route: 'credit-user',
    resource: 'transfer',
    action: ['read', 'update'],
  },
]
