export default [
  {
    path: '/api/setting',
    name: 'api-setting',
    component: () => import('@/views/api/Api'),
    meta: {
      resource: 'api',
      action: ['read', 'update'],
      pageTitle: 'Api Setting',
      breadcrumb: [
        {
          text: 'Api Setting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/api/log',
    name: 'api-log',
    component: () => import('@/views/api/Log'),
    meta: {
      resource: 'api',
      action: ['read', 'update'],
      pageTitle: 'Api Log',
      breadcrumb: [
        {
          text: 'Api Log',
          active: true,
        },
      ],
    },
  },
  {
    path: '/api/fail',
    name: 'api-fail',
    component: () => import('@/views/api/Fail.vue'),
    meta: {
      resource: 'api',
      action: ['read', 'update'],
      pageTitle: 'Api Fail',
      breadcrumb: [
        {
          text: 'Api Fail',
          active: true,
        },
      ],
    },
  },
]
