<template>
  <b-table
    responsive
    :fields="fields"
    :items="items"
    bordered
  >
    <template #cell(status)="data">
      <b-button
        style="width: 200px;"
        pill
        :variant="statusTextColor[data.value]"
      >
        {{ statusText[data.value] }}
      </b-button>
    </template>
    <template #cell(action)="data">
      <div>
        <b-button
          v-if="type !== 4 && isSingleWallet"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-50"
          @click="$router.push(`limit?id=${data.item.id}`)"
        >
          <feather-icon
            icon="SettingsIcon"
            class="mr-50"
          />
          <span class="align-middle">อั้นราคา</span>
        </b-button>
        <b-button
          v-if="type !== 4"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="$router.push(`limitNumber?id=${data.item.id}`)"
        >
          <feather-icon
            icon="SettingsIcon"
            class="mr-50"
          />
          <span class="align-middle">อั้นเลข</span>
        </b-button>
        <b-button
          v-if="$store.state.app.isMaster($store)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          class="ml-50"
          @click="$router.push(`edit?id=${data.item.id}`)"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span class="align-middle">เเก้ไข</span>
        </b-button>
        <!--        <b-button-->
        <!--          v-if="$store.state.app.isMaster($store)"-->
        <!--          v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--          variant="secondary"-->
        <!--          class="ml-50"-->
        <!--          @click="$router.push(`edit?id=${data.item.id}`)"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="EditIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span class="align-middle">เเก้ไข</span>-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          v-else-if="!$store.state.app.isMaster($store) && !isSingleWallet"-->
        <!--          v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--          class="ml-50"-->
        <!--          variant="secondary"-->
        <!--          @click="$router.push(`edit-transfer?id=${data.item.id}`)"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="EditIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span class="align-middle">กำหนดราคา</span>-->
        <!--        </b-button>-->
      </div>
    </template>

  </b-table>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import lottoService from '@/service/lottoService'
import agentService from '@/service/agentService'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BButton,
    // BAvatar,
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      statusText: [
        'ยังไม่ถึงเวลาเเทง',
        'อยู่ระหว่างเปิดให้เเทง',
        'หยุดพักชั่วคราว',
        'หมดเวลาเเทง',
        'อยู่ระหว่างคำนวนเครดิต',
        'คำนวนเครดิตเรียบร้อยเเล้ว',
        'รอจ่ายเครดิต',
        'อยู่ระหว่างจ่ายเครดิต',
        'อยู่ระหว่างคืนโพย',
        'ปิดรอบเเทงเรียบร้อยเเล้ว',
      ],
      statusTextColor: [
        'cyan-7',
        'teal',
        'danger',
        'warning',
        'secondary',
        'dark',
        'warning',
        'secondary',
        'secondary',
        'info-2',
      ],
      fields: [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'name',
          label: 'ชื่อ',
          class: 'text-nowrap',
        },
        {
          key: 'roundDate',
          label: 'รอบวันที่',
          class: 'text-center',
          thStyle: {
            'min-width': '110px',
          },
        },
        {
          key: 'roundStartDate',
          label: 'เวลาเปิดเเทง',
          class: 'text-center',
          thStyle: {
            'min-width': '140px',
          },
        },
        {
          key: 'roundEndDate',
          label: 'เวลาปิดเเทง',
          class: 'text-center',
          thStyle: {
            'min-width': '130px',
          },
        },
        {
          key: 'lastUpdate',
          label: 'อัพเดตล่าสุด',
          class: 'text-center',
          thStyle: {
            'min-width': '130px',
          },
        },
        // {
        //   key: 'isLimit',
        //   label: 'เลขอั้น',
        // },
        {
          key: 'status',
          label: 'สถานะ',
          class: 'text-center',
        },
        {
          key: 'action',
          label: 'กระทำ',
          class: 'text-center text-nowrap',
        },
      ],
      items: [],
      interval: null,
      isSingleWallet: true,
    }
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  mounted() {
    if (this.type === 4) {
      if (this.$store.state.app.isMaster(this.$store) === false) {
        this.fields.pop()
      }
    } else {
      agentService.getAuthen('percent').then(res => {
        if (res.status === 200) {
          this.isSingleWallet = res.data.walletType === 0
        }
      })
    }
    // console.log(this.type);
    [this.LoadGov, this.LoadStock, this.LoadYeeKee, this.LoadCustom][this.type - 1]()

    this.interval = setInterval(() => {
      lottoService
        .getAuthen('huay/states')
        .then(res => {
          if (res.status === 200) {
            for (let i = 0; i < this.items.length; i += 1) {
              this.items[i].status = res.data[this.items[i].id]
            }
          }
        })
    }, 5000)
  },
  methods: {
    async LoadGov() {
      this.loading = true
      const res = await lottoService.getAuthen('huay/1')
      setTimeout(() => {
        if (res.status === 200) {
          this.items = res.data
          this.loading = false
        }
      }, 100)
    },
    async LoadStock() {
      const [res, res2, res3] = await Promise.all([
        lottoService.getAuthen('huay/2'),
        lottoService.getAuthen('huay/3'),
        lottoService.getAuthen('huay/4'),
      ])
      setTimeout(() => {
        if (res.status === 200) {
          this.items = res.data.concat(res2.data)
            .concat(res3.data)
          this.loading = false
        }
      }, 100)
    },
    async LoadYeeKee() {
      this.loading = true
      const res = await lottoService.getAuthen('yeekee')
      setTimeout(() => {
        if (res.status === 200) {
          this.items = res.data
          this.loading = false
        }
      }, 100)
    },
    async LoadCustom() {
      this.loading = true
      const res = await lottoService.getAuthen('huay/5')
      setTimeout(() => {
        if (res.status === 200) {
          this.items = res.data
          this.loading = false
        }
      }, 100)
    },

  },
}
</script>
