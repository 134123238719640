export default [
  {
    path: '/summary/total-bet',
    name: 'total-bet',
    component: () => import('@/views/summary/TotalBet'),
    meta: {
      resource: 'summary',
      action: 'read',
      pageTitle: 'Total Bet',
      breadcrumb: [
        {
          text: 'Total Bet',
          active: true,
        },
      ],
    },
  },
  {
    path: '/summary/total-pay',
    name: 'total-pay',
    component: () => import('@/views/summary/TotalPay'),
    meta: {
      resource: 'summary',
      action: 'read',
      pageTitle: 'Total Pay',
      breadcrumb: [
        {
          text: 'Total Pay',
          active: true,
        },
      ],
    },
  }, {
    path: '/summary/total-profit',
    name: 'total-profit',
    component: () => import('@/views/summary/TotalProfit'),
    meta: {
      resource: 'summary',
      action: 'read',
      pageTitle: 'Total Profit',
      breadcrumb: [
        {
          text: 'Total Profit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/summary/total-poy',
    name: 'total-poy',
    component: () => import('@/views/summary/TotalPoy'),
    meta: {
      resource: 'summary',
      action: 'read',
      pageTitle: 'Total Poy',
      breadcrumb: [
        {
          text: 'Total Poy',
          active: true,
        },
      ],
    },
  },
  {
    path: '/summary/betNumber',
    name: 'bet-number',
    component: () => import('@/views/summary/BetNumber'),
    meta: {
      resource: 'summary',
      action: 'read',
      pageTitle: 'Bet Number',
      breadcrumb: [
        {
          text: 'Bet Number',
          active: true,
        },
      ],
    },
  },

]
