<template>
  <b-card
    :title="$t('System Status')"
    style="max-width: 800px"
  >
    <b-table
      ref="table"
      striped
      hover
      responsive
      :items="settings"
      :fields="fields"
      :busy.sync="loading"
      bordered
    >
      <template #cell(title)="data">
        {{ $t(data.item.title) }}
      </template>
      <template #cell(status)="data">
        <b-button
          v-if="data.item.status"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="toggleStatus(data.item,false)"
        >
          <span class="align-middle">ON</span>
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          @click="toggleStatus(data.item,true)"
        >
          <span class="align-middle">OFF</span>
        </b-button>
      </template>
      <template #cell(action)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          @click="showModal(data.item)"
        >
          <feather-icon icon="Edit3Icon" />
        </b-button>
      </template>
    </b-table>

    <b-modal
      v-model="modal.show"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      title="ข้อความสถานะ"
      button-size="sm"
      @ok="submitModal"
    >
      <b-form @submit.prevent>
        <b-form-group
          label="Message"
          label-for="message"
        >
          <b-form-input
            id="message"
            v-model="modal.item.message"
            autofocus
            placeholder="Message"
            @keydown.enter="submitModal"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton, BTable, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import systemService from '@/service/systemService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'Service',
          class: 'text-nowrap',
          thStyle: {
            width: '100px !important',
          },
        },
        {
          key: 'Status',
          label: 'status',
          class: 'text-nowrap',
          thStyle: {
            width: '100px !important',
          },
        },
        {
          key: 'message',
          label: 'Message',
          class: 'text-nowrap',
        },
        {
          key: 'Action',
          label: 'action',
          class: 'text-nowrap',
          thStyle: {
            width: '100px !important',
          },
        },
      ],
      settings: [],
      loading: false,
      modal: {
        show: false,
        item: {},
      },

    }
  },
  async mounted() {
    this.loading = true
    this.reloadTable()
  },
  methods: {
    async reloadTable() {
      const res = await systemService.getAuthen('setting')
      if (res.status === 200) {
        this.settings = res.data
        this.loading = false
      }
    },
    submitModal(e) {
      e.preventDefault()
      this.editData(this.modal.item, 'คุณเเน่ใจว่าต้องการเเก้ไขข้อมูลใช่หรือไม่')
    },
    showModal(item) {
      this.modal.show = true
      this.modal.item = { ...item }
    },
    toggleStatus(item, status) {
      const itemToUpdate = { ...item }
      itemToUpdate.status = status
      const statusText = status ? 'เปิด' : 'ปิด'
      this.editData(itemToUpdate, `คุณเเน่ใจว่าต้องการ ${statusText} ระบบใช่หรือไม่`)
    },
    async editData(data, infoText) {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: infoText,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        onOpen: () => {
          const btn = this.$swal.getConfirmButton()
          // eslint-disable-next-line no-shadow
          window.addEventListener('keypress', e => {
            if (e.key === 'Enter') {
              btn.click()
            }
          })
        },
      })
        .then(async result => {
          if (result.value) {
            const res = await systemService.putAuthen(`setting/${data.id}`, data)
            if (res.status === 200) {
              this.modal.show = false
              this.reloadTable()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: res.data,
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
