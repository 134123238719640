export default [
  {
    path: '/system/setting',
    name: 'system-setting',
    component: () => import('@/views/system/setting'),
    meta: {
      resource: 'system',
      action: 'update',
      pageTitle: 'System Setting',
      breadcrumb: [
        {
          text: 'System Setting',
          active: true,
        },
      ],
    },
  },
]
