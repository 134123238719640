export default [
  {
    path: '/lotto/list',
    name: 'lotto-list',
    component: () => import('@/views/lotto/LottoList'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto List',
      breadcrumb: [
        {
          text: 'Lotto List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/edit',
    name: 'lotto-edit',
    component: () => import('@/views/lotto/EditLotto'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Edit',
      breadcrumb: [
        {
          text: 'Lotto List',
          to: '/lotto/list',
        },
        {
          text: 'Lotto Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/edit-rate',
    name: 'lotto-edit-rate',
    component: () => import('@/views/lotto/EditLottoRate'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Edit Rate',
      breadcrumb: [
        {
          text: 'Lotto List',
          to: '/lotto/list',
        },
        {
          text: 'Lotto Edit Rate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/edit-transfer',
    name: 'lotto-edit-transfer',
    component: () => import('@/views/lotto/EditLottoTransfer'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Edit',
      breadcrumb: [
        {
          text: 'Lotto List',
          to: '/lotto/list',
        },
        {
          text: 'Lotto Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/limit',
    name: 'lotto-limit',
    component: () => import('@/views/lotto/Limit.vue'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Limit',
      breadcrumb: [
        {
          text: 'Lotto List',
          to: '/lotto/list',
        },
        {
          text: 'Lotto Limit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/limitNumber',
    name: 'lotto-limitNumber',
    component: () => import('@/views/lotto/LimitNumber.vue'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Limit Number',
      breadcrumb: [
        {
          text: 'Lotto List',
          to: '/lotto/list',
        },
        {
          text: 'Lotto Limit Number',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/result',
    name: 'lotto-result',
    component: () => import('@/views/lotto/LottoResult'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Result',
      breadcrumb: [
        {
          text: 'Lotto Result',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/result/round',
    name: 'lotto-round',
    component: () => import('@/views/lotto/LottoRound'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Round',
      breadcrumb: [
        {
          text: 'Lotto Result',
          to: '/lotto/result',
        },
        {
          text: 'Lotto Round',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/result/yeekee',
    name: 'lotto-round',
    component: () => import('@/views/lotto/YeekeeResult'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Yeekee Result',
      breadcrumb: [
        {
          text: 'Lotto Result',
          to: '/lotto/result',
        },
        {
          text: 'Yeekee Result',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/history',
    name: 'lotto-history',
    component: () => import('@/views/lotto/LottoHistory'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto History',
      breadcrumb: [
        {
          text: 'Lotto History',
          active: true,
        },
      ],
    },
  },
  {
    path: '/yeekee/history',
    name: 'yeekee-history',
    component: () => import('@/views/lotto/YeekeeHistory'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Yeekee History',
      breadcrumb: [
        {
          text: 'Yeekee History',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lotto/history/edit',
    name: 'lotto-history-edit',
    component: () => import('@/views/lotto/LottoHistoryEdit'),
    meta: {
      action: ['read', 'update'],
      resource: 'lotto',
      pageTitle: 'Lotto Result Edit',
      breadcrumb: [
        {
          text: 'Lotto Result Edit',
          active: true,
        },
      ],
    },
  },
]
