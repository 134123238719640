export default [
  {
    path: '/credit/Agent',
    name: 'credit-agent',
    component: () => import('@/views/credit/Agent.vue'),
    meta: {
      resource: 'transfer',
      action: ['read', 'update'],
      pageTitle: 'Agent Credit',
      breadcrumb: [
        {
          text: 'Add or Remove Credit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/credit/User',
    name: 'credit-user',
    component: () => import('@/views/credit/User.vue'),
    meta: {
      resource: 'transfer',
      action: ['read', 'update'],
      pageTitle: 'User Credit',
      breadcrumb: [
        {
          text: 'Add or Remove Credit',
          active: true,
        },
      ],
    },
  },
]
