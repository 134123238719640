<template>
  <b-card :title="`เเก้ไขข้อมูล ${huayData.name}`">
    <div v-if="!loaded">
      <LottoRateTransfer
        :huay-data="huayData"
        :huay-rate="huayRate"
      />

      <LottoConditionTransfer
        class="mt-1"
        :huay-data="huayData"
        :huay-condition="huayCondition"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import lottoService from '@/service/lottoService'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LottoRateTransfer from '@/views/components/LottoRateTransfer.vue'
import LottoConditionTransfer from '@/views/components/LottoConditionTransfer.vue'

export default {
  components: {
    LottoConditionTransfer,
    LottoRateTransfer,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: true,
      huayData: {
        name: '',
        externalLink: '',
        raw: '',
        start: '',
        end: '',
        autoDates: [false, false, false, false, false, false, false],
      },
      huayCondition: {},
      huayRate: {},
    }
  },
  async mounted() {
    this.loaded = true
    const { id } = this.$route.query
    if (id) {
      const [res, res2] = await Promise.all([
        lottoService.getAuthen(`huay/id/${id}`),
        lottoService.getAuthen(`rate/condition-transfer/${id}`),
      ])
      setTimeout(() => {
        if (res.status === 200) {
          const { autoDates } = { ...res.data }
          delete res.data.autoDates
          this.huayData = { ...res.data, autoDates: this.huayData.autoDates }
          if (autoDates !== null && autoDates !== undefined) {
            this.huayData.autoDates = autoDates
          }

          if (this.huayData.raw == null) {
            this.huayData.raw = ''
          }
        }

        if (res2.status === 200) {
          this.huayCondition = res2.data
          this.huayRate = res2.data
        }

        this.loaded = false
      }, 100)
    }
  },
  methods: {
    async save() {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const res = await lottoService.putAuthen(
              `huay/id/${id}`,
              {
                externalLink: this.huayData.externalLink,
                raw: this.huayData.raw,
              },
            )
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.custom-control-pink .custom-control-input:checked ~ .custom-control-label::before ,
.custom-control-pink .custom-control-input:active ~ .custom-control-label::before{
  border-color: hotpink !important;
  background-color: hotpink !important;
  box-shadow: 0 2px 4px 0 rgba(255, 192, 203, 0.4) !important;
}

.custom-control-yellow .custom-control-input:checked ~ .custom-control-label::before ,
.custom-control-yellow .custom-control-input:active ~ .custom-control-label::before{
  border-color: rgba(235, 178, 67, 0.99) !important;
  background-color: rgba(235, 178, 67, 0.99) !important;
  box-shadow: 0 2px 4px 0 rgba(255, 178, 67, 0.4) !important;
}

</style>
