<template>
  <div>
    <b-card>
      <b-form
        style="max-width: 1024px;"
        @submit.prevent
      />
      <validation-observer ref="simpleRulesCredit">
        <b-form
          style="max-width: 1024px;text-align: left"
          @submit.prevent
        >
          <b-row class="mt-1 text-left text-md-center align-items-top align-content-start">
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Your Credit"
                label-for="mc-pt"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <b-form-input
                  id="mc-pt"
                  v-model="creditSetting.credit"
                  placeholder="Credit"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label="Agent"
                label-for="mc-username"
                label-cols-lg="3"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="mc-username"
                    v-model="form.username"
                    placeholder="Agent Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="$can('update', 'transfer')"
              cols="12"
              md="5"
            >
              <b-form-group
                label="Credit"
                label-for="mc-pt"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <validation-provider
                  v-if="creditSetting.isMaster"
                  #default="{ errors }"
                  name="Credit"
                  rules="required|decimal"
                >
                  <b-form-input
                    id="mc-pt"
                    v-model="form.credit"
                    type="number"
                    placeholder="Credit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  v-else
                  #default="{ errors }"
                  name="Credit"
                  :rules="`required|decimal|max_value:${creditSetting.credit}`"
                >
                  <b-form-input
                    id="mc-pt"
                    v-model="form.credit"
                    type="number"
                    placeholder="Credit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="5"
            >
              <b-form-group
                label-cols-lg="3"
                label-cols-md="4"
                style="text-align: left"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click.prevent="onSubmitForm"
                >
                  Submit
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <b-row class="justify-content-between">
        <b-col
          cols="2"
          class="mb-sm-1"
        >
          <b-form-group class="text-nowrap mb-0">

            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
              style="min-width: 100px"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          md="6"
          sm="8"
          class="mb-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filterForSearch"
                type="search"
                placeholder="Type prefix or username to search"
                @keydown.enter="filterSearch"
              />
              <b-input-group-append>
                <b-button
                  @click="filterSearch"
                >
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-table
            ref="table"
            striped
            hover
            responsive
            bordered
            :per-page="perPage"
            :current-page="currentPage"
            :items="reloadData"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy.sync="loading"
            :filter="filter"
          >
            <template #cell(amount)="v">
              <span
                v-if="v.value === 0"
                class="font-weight-bolder"
              >  {{ toNumber(v.value.toFixed(2)) }} </span>
              <span
                v-else-if="v.value > 0"
                class="text-success font-weight-bolder"
              >  {{ toNumber(v.value.toFixed(2)) }} </span>
              <span
                v-else
                class="text-danger font-weight-bolder"
              >  {{ toNumber(v.value.toFixed(2)) }} </span>
            </template>
            <template #cell(beforeAmount)="v">
              {{ toNumber(v.value.toFixed(2)) }}
            </template>
            <template #cell(afterAmount)="v">
              {{ toNumber(v.value.toFixed(2)) }}
            </template>
            <template #cell(ownerAfter)="v">
              <div v-if="v.item.agent==='master'">
                unlimited
              </div>
              <div v-else>
                {{ toNumber(v.value.toFixed(2)) }}
              </div>
            </template>
            <template #cell(type)="v">
              <span
                class="font-weight-bolder"
              >
                {{ v.value }}
              </span>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton, BForm, BBadge, BTable, BPagination, BFormSelect, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import agentService from '@/service/agentService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BForm,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
  },
  data() {
    return {
      form: {
        username: '',
        credit: '',
      },
      eventTypes: ['ทั้งหมด', 'BET', 'PAYOUT', 'CANCEL', 'CANCEL_NUMBER', 'VOID'],
      requestModal: false,
      requestData: '',
      responseModal: false,
      responseData: '',
      loading: false,
      perPage: 25,
      pageOptions: [1, 5, 10, 25, 50, 100, 150, 250, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'createAt',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterForSearch: '',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'createAt',
          label: 'วันเวลา',
          // sortable: true,
        },
        {
          key: 'agent',
          label: 'agent',
          class: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'To Agent',
          class: 'text-nowrap',
        },
        {
          key: 'beforeAmount',
          label: 'before',
          class: 'text-nowrap',
        },
        {
          key: 'amount',
          label: 'amount',
          class: 'text-nowrap',
        },
        {
          key: 'afterAmount',
          label: 'after',
          class: 'text-nowrap',
        },
        {
          key: 'ownerAfter',
          label: 'remaining',
          class: 'text-nowrap',
        },
      ],
      creditSetting: {
        load: false,
        isMaster: false,
        credit: 0,
        walletType: 0,
      },
    }
  },
  computed: {
    toNumber() {
      return this.$store.state.app.toNumber
    },
  },
  mounted() {
    agentService.getAuthen('wallet-status')
      .then(res => {
        this.creditSetting.load = true
        this.creditSetting.isMaster = res.data.isMaster
        this.creditSetting.credit = res.data.credit
        this.creditSetting.walletType = res.data.walletType
      })
  },
  methods: {
    onSubmitForm() {
      const text = this.form.credit > 0 ? `คุณต้องการ เเอดเครดิต ${this.form.credit} ใช่หรือไม่` : `คุณต้องการลบเครดิต ${this.form.credit} ใช่หรือไม่`
      this.$refs.simpleRulesCredit.validate()
        .then(async success => {
          if (success) {
            this.$swal({
              dark: true,
              title: 'Are you sure?',
              text,
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
              allowEnterKey: true,
              focusConfirm: true,
              inputAutoFocus: true,
            })
              .then(async result => {
                if (result.value) {
                  const form = {
                    credit: parseFloat(this.form.credit),
                  }

                  const res = await agentService.putAuthen(`credit?username=${this.form.username}`, form)
                  if (res.status === 200) {
                    this.form.credit = `${res.data.agentCredit}`
                    this.creditSetting.credit = `${res.data.ownerCredit}`
                    this.$refs.table.refresh()
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'success',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'ดำเนินการสำเร็จ',
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: res.data ? res.data : 'sorry , something wrong please try again',
                      },
                    })
                  }
                }
              })
          }
        })
    },
    onSubmit() {
      const {
        prefix,
        username,
        type,
        roundId,
        betId,
      } = this.form

      this.filter = ''
      if (prefix !== '') {
        this.filter += `&prefix=${prefix}`
      }

      if (username !== '') {
        this.filter += `&username=${username}`
      }

      if (type !== '' && type !== 'ทั้งหมด') {
        this.filter += `&type=${type}`
      }

      if (roundId !== '') {
        this.filter += `&round=${roundId}`
      }

      if (betId !== '') {
        this.filter += `&poy=${betId}`
      }
    },
    filterSearch() {
      this.filter = this.filterForSearch
    },
    showUserDetial(username) {
      this.$router.push({
        name: 'agetn-edit',
        query: { id: username },
      })
    },
    async reloadData() {
      const startRow = (this.currentPage - 1) * this.perPage
      // if (this.filter === '') {
      //   return []
      // }
      this.loading = true
      const res = await agentService.getAuthen(`credits?filter=${this.filter}&limit=${this.perPage}&page=${startRow}&sortBy=${this.sortBy}&desc=${this.sortDesc}`)
      if (res.status === 200) {
        this.totalRows = res.data.count
        this.loading = false
        const { list } = res.data
        if (this.sortDesc === false) {
          let index = startRow + 1
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index += 1
          }
        } else {
          let index = res.data.count
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index -= 1
          }
        }
        return list
      }
      this.loading = false
      return []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.col {
  text-align: left !important;
}

table {
  .sr-only {
    width: 0px !important;
  }

  .dropdown-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.btn-group {
  .btn {
    padding: 5px;
  }
}

.text-effect:hover {
  font-weight: bolder;
}

.h-100[role="columnheader"] {
  height: auto !important;
}

.h-100 {
  height: 100px !important;
}
</style>
